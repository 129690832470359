<template>
  <view-item title="经营状况一览表">
    <template #operation>
      <en-dropdown @command="operation.export.command">
        <en-button type="primary"
          >导出<en-icon class="ml-1"><ArrowDown /></en-icon
        ></en-button>
        <template #dropdown>
          <en-dropdown-item command="exports">导出</en-dropdown-item>
          <en-dropdown-item command="operator">导出运营统计 </en-dropdown-item>
          <en-dropdown-item command="year">导出年度运营统计 </en-dropdown-item>
        </template>
      </en-dropdown>
    </template>
    <en-card>
      <div class="h-full">
        <form-query :method="outin.table.get">
          <en-form-item label="查询日期">
            <en-date-picker
              v-model:start="outin.form.data.startDate"
              v-model:end="outin.form.data.endDate"
              type="daterange"
              value-format="YYYY-MM-DD"
              :clearable="false"
            ></en-date-picker>
          </en-form-item>
        </form-query>
        <flex-box>
          <div class="statement-output">
            <span class="statement-output-title" v-for="item in outin.table.data">
              <span>维修收入: {{ formatMoney(item.serviceOutputValueStatistics?.amount, 2) }} </span>
              <span>毛利: {{ formatMoney(item.serviceOutputValueStatistics?.profits, 2) }}</span>
              <span>结算台次: {{ item.serviceOutputValueStatistics?.count }}</span>
            </span>
            <span class="statement-output-titled" v-for="item in outin.table.data">
              <span
                >工时收入:
                {{ formatMoney(item.serviceOutputValueStatistics?.maintenanceAmount, 2) }}
              </span>
              <span>工时折扣: {{ formatMoney(item.serviceOutputValueStatistics?.maintenanceDiscountAmount, 2) }}</span>
              <span>配件收入: {{ formatMoney(item.serviceOutputValueStatistics?.goodsAmount, 2) }}</span>
              <span>配件折扣: {{ formatMoney(item.serviceOutputValueStatistics?.goodsDiscountAmount, 2) }}</span>
              <span>配件管理费: {{ formatMoney(item.serviceOutputValueStatistics?.managementAmount, 2) }}</span>
              <span>税金: {{ formatMoney(item.serviceOutputValueStatistics?.tax, 2) }}</span>
              <span>其他费用: {{ formatMoney(item.serviceOutputValueStatistics?.otherAmount, 2) }}</span>
              <span>工单减免: {{ formatMoney(item.serviceOutputValueStatistics?.discount, 2) }}</span>
              <span>卡券抵扣: {{ formatMoney(item.serviceOutputValueStatistics?.couponDiscount, 2) }}</span>
              <span>抹零金额: {{ formatMoney(item.serviceOutputValueStatistics?.autoEraseAmount, 2) }}</span>
            </span>
          </div>
          <div class="statement-outputd">
            <span class="statement-output-title" v-for="item in outin.table.data">
              <span>保险收入: {{ formatMoney(item.policyOutputValueStatistics?.amount, 2) }} </span>
              <span>保险台次: {{ item.policyOutputValueStatistics?.count }}</span>
            </span>
          </div>
          <div class="statement-output">
            <span class="statement-output-title" v-for="item in outin.table.data">
              <span>销售收入: {{ formatMoney(item.saleOutputValueStatistics?.returnAmount+item.saleOutputValueStatistics?.saleAmount, 2) }} </span>
              <span>销售单数: {{ calculator.add(item.saleOutputValueStatistics.returnCount, item.saleOutputValueStatistics.saleCount) }}</span>
            </span>
            <span class="statement-output-titled" v-for="item in outin.table.data">
              <span
                >销售成本: {{ calculator.add(item.saleOutputValueStatistics.returnGoodsCost, item.saleOutputValueStatistics.saleGoodsCost) }}
              </span>
              <span>其它金额: {{ formatMoney(item.saleOutputValueStatistics?.returnOtherAmount+item.saleOutputValueStatistics?.saleOtherAmount) }}</span>
              <span>退货金额: {{ formatMoney(item.saleOutputValueStatistics?.returnReceivedAmount, 2) }}</span>
            </span>
          </div>
          <div class="statement-output">
            <span class="statement-output-title" v-for="item in outin.table.data">
              <span
                >本期收款合计:
                {{
                  formatMoney(
                    calculator.add(
                      item.serviceOutputValueStatistics.receivedAmount,
                      calculator.add(item.saleOutputValueStatistics.returnReceivedAmount, item.saleOutputValueStatistics.saleReceivedAmount)
                    )
                  )
                }}
              </span>
            </span>
            <span class="statement-output-titled" v-for="item in outin.table.data">
              <span>工单收款: {{ formatMoney(item.serviceOutputValueStatistics?.receivedAmount, 2) }} </span>
              <span
                >配件销售收款:
                {{
                  formatMoney(calculator.add(item.saleOutputValueStatistics.returnReceivedAmount, item.saleOutputValueStatistics.saleReceivedAmount))
                }}</span
              >
              <span>保险收款: {{ formatMoney(item.policyOutputValueStatistics?.receivedAmount, 2) }}</span>
            </span>
          </div>
          <div class="statement-output">
            <span class="statement-output-title" v-for="item in outin.table.data">
              <span>本期预收款合计: {{ formatMoney(item.advancesStatistics?.totalAmount, 2) }} </span>
            </span>
            <span class="statement-output-titled" v-for="item in outin.table.data">
              <span>充值金额: {{ formatMoney(item.advancesStatistics?.chargeableAmount, 2) }} </span>
              <span>预收款: {{ formatMoney(item.advancesStatistics?.advanceAmount, 2) }}</span>
              <span>套餐: {{ formatMoney(item.advancesStatistics?.schemeAmount, 2) }}</span>
              <span>充值余额: {{ formatMoney(item.advancesStatistics?.chargeableBalanceAmount, 2) }}</span>
              <span>预收款金额: {{ formatMoney(item.advancesStatistics?.advanceBalanceAmount, 2) }}</span>
            </span>
          </div>
          <div class="statement-output">
            <span class="statement-output-title" v-for="item in outin.table.data">
              <span>其他指标 </span>
            </span>
            <span class="statement-output-titled" v-for="item in outin.table.data">
              <span>索赔工时: {{ formatMoney(item.serviceOutputValueStatistics?.claimMaintenanceAmount, 2) }} </span>
              <span>返修工时: {{ formatMoney(item.serviceOutputValueStatistics?.reworkMaintenanceAmount, 2) }}</span>
              <span>索赔配件: {{ formatMoney(item.serviceOutputValueStatistics?.claimGoodsAmount, 2) }}</span>

              <span>返修配件: {{ formatMoney(item.serviceOutputValueStatistics?.reworkGoodsAmount, 2) }}</span>
            </span>
          </div>
        </flex-box>
      </div>

      <span>仓库出库情况</span>
      <en-table :data="outin.table.data.detail" :loading="outin.table.loading" :summary-method="outin.table.summaryMethod" show-summary>
        <en-table-column label="仓库名" prop="warehouseName"> </en-table-column>
        <en-table-column label="维修结算收入" prop="serviceAmount"> </en-table-column>
        <en-table-column label="维修结算成本" prop="serviceCost"> </en-table-column>
        <en-table-column label="销售收入" prop="saleOutAmount"> </en-table-column>
        <en-table-column label="销售成本" prop="saleOutCost"> </en-table-column>
      </en-table>
    </en-card>
    <en-dialog v-model="year.visible">
      <en-date-picker v-model="year.form.data.year" placeholder="选择年份" type="year" value-format="YYYY"></en-date-picker>
      <template #footer>
        <en-button @click="footer.cancel.click">取消</en-button>
        <button-ajax :method="footer.confirm.click">确定</button-ajax>
      </template>
    </en-dialog>
  </view-item>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { type TableColumnCtx } from '@enocloud/components'
import { ArrowDown } from '@element-plus/icons-vue'
import { calculator } from '@enocloud/utils'


export default factory({
  components: { ArrowDown },
  config: {
    children: {
      operation: {
        children: {
          year: {
            click() {
              this.year.visible = true
            }
          },
          export: {
            ajax: {
              exports: {
                action: 'GET /enocloud/settlement/statistics/output/export',
                params(params) {
                  params.payload = this.outin.form.data
                }
              },
              operator: {
                action: 'GET /enocloud/settlement/statistics/business/analysis/export',
                params(params) {
                  params.payload = this.outin.form.data
                }
              }
            },
            async command(option: string) {
              switch (option) {
                case 'exports':
                  await this.operation.export.exports()
                  this.store.openDownload()
                  break
                case 'operator':
                  await this.operation.export.operator()
                  this.store.openDownload()
                  break
                case 'year':
                  await this.operation.year.click()
              }
            }
          }
        }
      },
      outin: {
        children: {
          form: {
            is: 'form',
            data: {
              startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD')
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/output',
                data: 'array',
                loading: true,
                params(params) {
                  params.payload = this.outin.form.data
                },
                convert(data) {
                  data.detail = data[0].warehouseOutputValueStatisticsList
                }
              }
            },
            summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
              columns: TableColumnCtx<T>[]
              data: T[]
            }) {
              return ctx.columns
                .map((item) => item.property)
                .map((key, index) => {
                  if (['serviceAmount', 'serviceCost','saleOutAmount','saleOutCost' ].includes(key)) {
                    return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
                  } else return !index ? '合计' : '-'
                })
            },
            
            
          }
        }
      },
      year: {
        visible: false,
        children: {
          form: {
            data: {
              year: ''
            },
            ajax: {
              get: {
                action: 'GET /enocloud/statement/annual/business/statement',
                data: 'array',
                loading: true,
                params(params) {
                  params.payload = this.year.form.data
                }
              }
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.year.visible = false
          }
        },
        confirm: {
          async click() {
            this.year.form.get().then(() => {
              this.store.openDownload()
              this.year.visible = false
            })
          }
        }
      }
    }
  },
  async mounted() {
    await this.outin.table.get()

  }
})
</script>

<style lang="scss" scoped>
.statement-output {
  height: 124px;
  background: #fafafaff;
  margin: 0 auto;
  margin-bottom: 20px;
}
.statement-outputd {
  height: 86px;
  background: #fafafaff;
  margin: 0 auto;
  margin-bottom: 20px;
}
.statement-output-title {
  display: block;
  padding: 22px 0;
  color: #4c58d9ff;
  font-weight: Medium;
  font-size: 16px;
  span {
    padding: 0 22px 0;
  }
}
.statement-output-titled {
  display: block;

  color: #000000ff;

  font-size: 16px;
  span {
    padding: 0 22px 0;
  }
}
</style>
